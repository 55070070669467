/**
 * Sticky Header.
 *
 * @package Schibli;
 * @author  Valentin Zmaranda
 * @license GPL-2.0+
 */

const stickyHeader = ((window, $) => {
	/**
	 * Adjust site inner margin top to compensate for sticky header height.
	 *
	 * @since 2.6.0
	 */

	// eslint-disable-next-line @wordpress/no-unused-vars-before-return,no-unused-vars
	const siteHeader = $('.site-header');
	// const headerHeight = document.querySelector('.logo-wrapper').offsetHeight;
	const siteInner = $('.site-inner');

	let initializer = false;
	// let siteInnerMarginTop = 0;
	const mq = window.matchMedia('(min-width: 1025px)');

	const fixedWithPrimaryNav = () => {
		// console.log('resize 2');
		// if (windowWidth) {
		let widthChange;
		let windowWidth;
		/* JavaScript Media Queries */
		if (matchMedia) {
			widthChange = (mediaQuery) => {
				return mediaQuery.matches;
			};
			windowWidth = widthChange(mq);
		}

		// console.log('yoohoooo', windowWidth);

		const siteHeaderHeight = siteHeader.outerHeight(true);
		if (!windowWidth) {
			siteInner.css({ marginTop: siteHeaderHeight });
		}
		$('.menu-toggle').css({ top: siteHeaderHeight / 2 - 12.5 });
		// console.log('siteHeaderHeight', siteHeaderHeight);

		const distanceFromTop = siteInner.offset().top;
		// console.log('distanceFromTop', distanceFromTop);
		if (windowWidth) {
			$(window).on('scroll', function() {
				const sticky = $('header.site-header'),
					scroll = $(window).scrollTop();

				if (scroll >= distanceFromTop + 47) {
					// console.log('scroll', scroll);
					// console.log('windowWidth', windowWidth);

					if (initializer === false) {
						// sticky.addClass('fixed');

						// console.log('windowWidth', windowWidth);
						sticky
							.hide('slow', function() {
								const $header = $(this);
								$header.addClass('fixed');
								setTimeout(function() {
									// $header.fadeIn(800);
									$header.fadeTo(500, 1);
									// $header.addClass('clear-opacity');
								}, 320);
							})
							.finish();
						siteInner.css({ marginTop: siteHeaderHeight });
						initializer = true;
					}
				} else if (scroll < distanceFromTop + 47) {
					// console.log('else', siteInnerMarginTop);
					if (windowWidth) {
						sticky.removeClass('fixed');
						siteInner.removeAttr('style');
					}

					// siteInner.css('margin-top', 0);
					initializer = false;
				}
			});
		}
	};

	const _moveContentBelowFixedHeader = () => {
		fixedWithPrimaryNav();
	};
	const init = () => {
		_moveContentBelowFixedHeader();
		$(window).resize(() => {
			initializer = false;
			_moveContentBelowFixedHeader();
		});
	};

	return {
		init,
	};
})(window, jQuery);

export default stickyHeader;

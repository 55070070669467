/**
 * External dependencies
 */
// eslint-disable-next-line no-unused-vars
import $ from 'jquery';

/**
 * Internal dependencies
 */
import './inc/responsive-menus';
import stickyHeader from './inc/sticky-header';
import searchForm from './inc/menu-search-form';
import linkScroll from './inc/smooth-scroll';
import slider from './inc/slider';
import gridMenu from './inc/grid-menu';

$(window).on('load', stickyHeader.init);

$(window).on('load', function() {
	setTimeout(function() {
		searchForm.init();
	}, 500);
});

window.addEventListener('DOMContentLoaded', gridMenu.init);
// $(window).on('load', squares.init);
$(window).on('load', slider.init);
setTimeout(linkScroll.init, 320);
// require.context('../../../icons', true, /\.svg$/);

// document.querySelectorAll('*').forEach((el) => {
// 	if (el.offsetWidth > document.documentElement.offsetWidth) {
// 		console.log('Found the worst element ever: ', el);
// 	}
// });

const slider = ((window, $) => {
	const init = () => {
		const pageSlider = $("[rel='slider']").not('.slick-initialized');
		// console.log('pageSlider', pageSlider);
		if (pageSlider.length) {
			pageSlider.slick({
				// slidesToShow: 3,
				// infinite: true,
				slidesToScroll: 1,
				responsive: [
					{
						breakpoint: 3280,
						settings: {
							slidesToShow: 3,
							// infinite: true,
							slidesToScroll: 1,
						},
					},
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 2,
							// infinite: true,
							slidesToScroll: 1,
						},
					},
					{
						breakpoint: 600,
						settings: {
							slidesToShow: 1,
							// infinite: true,
						},
					},
				],
			});
		}
	};

	return {
		init,
	};
})(window, jQuery);

export default slider;

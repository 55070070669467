// eslint-disable-next-line no-unused-vars
const gridMenu = ((window) => {
	const init = function() {
		const theMenu = document.querySelector('.menu-primary-grid');
		if (!theMenu) {
			return false;
		}
		setRootProperties(theMenu);
		window.addEventListener('resize', () => {
			setRootProperties(theMenu);
		});
		window.addEventListener('scroll', (e) => {
			setRootProperties(theMenu, e.type);
		});
	};
	const setRootProperties = function(theMenu, eventType = null) {
		const firstLevelsWithChildren = _getOnlyParentElementWithChildren(
				theMenu
			),
			headerWrapElement = document.querySelector('.site-header > .wrap');

		setTimeout(function() {
			firstLevelsWithChildren.forEach((withChildren) => {
				const listItemHeight = _getElementTotalHeight(withChildren);
				withChildren.style.setProperty(
					`--child-left`,
					`${-190 + withChildren.offsetWidth / 2}px`
				);
				withChildren.style.setProperty(
					`--child-top`,
					`${
						eventType !== 'scroll' || window.scrollY === 0
							? listItemHeight
							: listItemHeight + 3
					}px`
				);
				withChildren.style.setProperty(
					`--child-mega-top`,
					`${
						eventType !== 'scroll' || window.scrollY === 0
							? headerWrapElement.clientHeight
							: headerWrapElement.clientHeight + 2
					}px`
				);
			});
		}, 1000);
		removeHashFromCustomLink(theMenu);
	};
	const _getElementTotalHeight = (element) => {
		const style = window.getComputedStyle(element);

		return [
			'height',
			'margin-top',
			'margin-bottom',
			'padding-top',
			'padding-bottom',
		]
			.map((key) => {
				return Math.floor(parseInt(style.getPropertyValue(key), 10));
			})
			.reduce((accumulator, currentValue) => accumulator + currentValue);
	};
	const _getOnlyParentElementWithChildren = (theMenu) => {
		const parentItems = [
			...theMenu.querySelectorAll('.menu-item-has-children'),
		];
		return parentItems.filter((element) => {
			return element.parentElement === theMenu;
		});
	};
	const removeHashFromCustomLink = function(theMenu) {
		const elements = [...theMenu.querySelectorAll('.menu-item')];
		elements.forEach((item) => {
			if (
				item.children[0].href === '' ||
				item.children[0].href?.indexOf('#') !== -1
			) {
				item.children[0].href = 'javascript:void(0)';
				item.children[0].style.cursor = 'inherit';
			}
		});
	};
	return {
		init,
	};
})(window);

export default gridMenu;
